import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_ADMINISTRACAO_PORT}${process.env.VUE_APP_API_PATH}/administracao`;

export default {
    obterTodos() {
        return axiosJwt.get(`${api}/parametros`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/parametros/${id}`);
    },

    inserir(data) {
        return axiosJwt.post(`${api}/parametros`, data);
    },

    atualizar(data) {
        return axiosJwt.put(`${api}/parametros`, data);
    },
};
